
import { Options, Vue } from 'vue-class-component';
import Recepcion from '@/components/Repecion.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    Recepcion,
  },
})
export default class RecepcionView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}


import { Vue } from 'vue-class-component';
import router from '@/router/index'
import { getAuth, signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useToast } from "primevue/usetoast";
import { readUser } from "@/firebase/firestore/fsServices"

export default class Login extends Vue {
    //Import variables
    toast = useToast();
    auth = getAuth();

    user: string;
    password: string;

    created(): void {
        this.user = null;
        this.password = null;
    }

    iniciarSesion() {
        if (this.user && this.password) {
            this.user += "@bipperapp.com";
            signInWithEmailAndPassword(this.auth, this.user, this.password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    readUser(user.uid).then((data) => {
                        if (data) {
                            // Signed in 
                            router.push({ path: "/" });
                        } else {
                            deleteUser(user).then(() => {
                                this.toast.add({ severity: 'error', summary: 'Error', detail: 'El usuario o contraseña ya no existen.', life: 5000 });
                            }).catch((error) => {
                                this.toast.add({ severity: 'error', summary: error.code, detail: error.message, life: 5000 });
                            });
                        }
                    })
                }).catch((error) => {
                    this.toast.add({ severity: 'error', summary: error.code, detail: error.message, life: 5000 });
                });
        } else {
            this.toast.add({ severity: 'warn', summary: 'Validación', detail: 'Verifique el formulario', life: 5000 });
        }
    }
}

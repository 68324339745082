
import { Options, Vue } from 'vue-class-component';
import EditUser from '@/components/usuarios/EditUsuer.vue'
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
    components: {
        EditUser,
    },
})
export default class EditUserView extends Vue {
    beforeCreate(): void {
        isCurrentUserExist();
    }
}

import { auth } from '../config'
import { onAuthStateChanged } from "firebase/auth";
import { deleteUser } from "firebase/auth";
import router from '@/router/index'

export async function getCurrentUsername() {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user.displayName);
            } else {
                resolve("unknown");
            }
            unsubscribe(); // Detén la escucha una vez que hayas obtenido el valor.
        });
    });
}

export function isCurrentUserExist() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            return true;
        } else {
            // User is signed out   
            router.push({ path: "/login" });
        }
    });
}

export function loginIsCurrentUserExist() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            router.push({ path: "/" });
        }
    });
}

export function deleteUnavilableUser(user: any) {
    deleteUser(user).then(() => {
        return true;
    }).catch((error) => {
        return false;
    });
}




import { Options, Vue } from 'vue-class-component';
import Habitaciones from '@/components/habitaciones/Habitaciones.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'


@Options({
    components: {
        Habitaciones,
    },
})
export default class PacientesView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}


import { Options, Vue } from 'vue-class-component';
import NotFound from '@/components/NotFound.vue'// @ is an alias to /src

@Options({
    components: {
        NotFound,
    },
})
export default class NotFoundView extends Vue {
}



import { Options, Vue } from 'vue-class-component';
import Notificaciones from '@/components/Notificaciones.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    Notificaciones,
  },
})

export default class NotificacionesView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}



import { Options, Vue } from 'vue-class-component';
import EditHabitacion from '@/components/habitaciones/EditHabitacion.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
    components: {
        EditHabitacion,
    },
})
export default class EditPacienteView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}

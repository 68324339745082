
import { Options, Vue } from 'vue-class-component';
import Login from '@/components/Login.vue'// @ is an alias to /src
import { loginIsCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
    components: {
        Login,
    },
})
export default class LoginView extends Vue {
    beforeCreate(): void {
        loginIsCurrentUserExist();
    }
}

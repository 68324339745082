import { storage } from '../config'
import { ref } from "firebase/storage";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export async function uploadHabitacionQRFile(nameHabitacion: string, file: any) {
    return new Promise((resolve, reject) => {
        let path = 'habitaciones/' + nameHabitacion + '/' + "QR-" + nameHabitacion;
        let habitacionesRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(habitacionesRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
}
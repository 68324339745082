import { Component, Vue } from 'vue-facing-decorator'
import { getAllNotificactions } from '@/firebase/realtimeDB/services'
import { saveNotification } from '@/firebase/realtimeDB/services';
import { deleteNotificacion } from '@/firebase/realtimeDB/services';
import moment from 'moment';
import router from '@/router/index'

@Component
export default class Notificaciones extends Vue {
    notificaciones?: any[];

    beforeCreate(): void {
        getAllNotificactions().then((data: any) => {
            this.notificaciones = data;
            console.log(data);
        });
    }

    formattedDate(date: any) {
        return moment(new Date(date)).format('DD/MM/YYYY - HH:mm');
    }

    checkNotificacion(notification: any) {
        notification.status = 2;
        saveNotification(notification, notification.uid);
        router.push('/editHabitacion/' + notification.data);
    }

    deleteNotification(uidNotification: string) {
        deleteNotificacion(uidNotification).then((data) => {
            getAllNotificactions().then((data: any) => {
                this.notificaciones = data;
                console.log(data);
            });
        });
    }


}

import { Component, Vue } from 'vue-facing-decorator';
import router from '@/router/index';
import { useToast } from "primevue/usetoast";
import Swal from 'sweetalert2';
import { findAllPersonal, deleteDoctor } from '@/firebase/firestore/fsServices';

@Component
export default class Doctores extends Vue {
    toast = useToast();
    doctores?: any[];

    beforeMount() {
        findAllPersonal().then((data) => {
            this.doctores = data;
        });
    }

    public editDoctor(id: string) {
        router.push('/editDoctor/' + id);
    }

    public deleteDoctor(doctor: any) {
        Swal.fire({
            title: '¿Desea eliminar la informacion del doctor: ' + doctor.nombre + '?',
            text: 'Esta acción es irreversible',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDoctor(doctor).then(() => {
                    findAllPersonal().then((data) => {
                        this.doctores = data;
                        this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'El registro se eliminó correctamente.', life: 3600 });
                    });
                });
            }
        });
    }
}
import { Component, Vue } from 'vue-facing-decorator';
import router from '@/router/index';
import { useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";
import { readDoctor } from '@/firebase/firestore/fsServices';
import { updateDoctor } from '@/firebase/firestore/fsServices';

@Component
export default class EditDoctor extends Vue {
    toast = useToast();
    route = useRoute();
    doctor?: any = {};
    idDoctor?: any;
    loader = false;
    mensaje: string = "";
    fecha1: Date;

    tipos: any = [
        { id: 1, valor: "Enfermero" },
        { id: 2, valor: "Doctor" },
      ];

    created(): void {
        this.idDoctor = this.route.params.idDoctor;
        readDoctor(this.idDoctor).then((data) => {
            this.doctor = data;
            this.doctor.id = this.idDoctor;
            this.fecha1 = new Date(data.fechaNaci);
        });
    }

    public guardarDoctor() {
        if (this.validateDoctor()) {
            this.formatFechas();
            if (this.doctor.tipo == "Doctor") {
                this.doctor.nombre = `Dr. ${this.doctor.nombre}`;   
            }
            updateDoctor(this.doctor).then((data) => {
                router.push('/doctores');
                this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se actualizó la información del doctor', life: 3600 });
            });
        } else {
            this.toast.add({ severity: 'error', summary: 'Formulario', detail: this.mensaje, life: 3600 });
        }
    }

    private validateDoctor() {
        if (this.doctor.nombre == null) {
            this.mensaje = "EL Nombre no puede estar vacío.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.doctor.especialidad == null) {
            this.mensaje = "La especialidad 1 no puede estar vacía.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.doctor.tel == null) {
            this.mensaje = "el teléfono no puede estar vacío.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.fecha1 == null) {
            this.mensaje = "La fecha de nacimiento no puede estar vacía.";
            return false;
        }
        return true;
    }

    private formatFechas() {
        this.doctor.fechaNaci = this.fecha1.getTime();
    }
}
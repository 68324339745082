export const defaultMsjsEnfermeria = [
    {
        remitente: "Enfermería",
        mensaje: "Buenas noticias, tu Dr. ya solicitó tu alta, esperamos que hayas tenido una estancia placentera, nos encantó acompañarte durante tu proceso hospitalario.  Antes de irte, ayúdanos a contestar la encuesta de satisfacción.",
        prioridad: 3,
        tipo: "text",
        status: 1
    },
    {
        remitente: "Enfermería",
        mensaje: "Buenas noticias, tu Dr. ya solicitó tu alta y la de tu bebé, esperamos que esta nueva este llena de increíbles momentos, gracias por permitirnos acompañarte en este momento.",
        prioridad: 3,
        tipo: "text",
        status: 1
    },
    {
        remitente: "Enfermería",
        mensaje: " ¡Hola! En unos minutos uno de nuestros enfermeros estará pasando a tu habitación a suministrarte tus medicamentos.",
        prioridad: 3,
        tipo: "text",
        status: 1
    },
];

export const defaultMsjsRecepcion = [
    {
        remitente: "Recepción",
        mensaje: "Bienvenido(a) a Sanatorio México, para nosotros siempre vas a estar PRIMERO TÚ, por eso queremos acompañarte durante toda tu estancia hospitalaria brindándote la confianza, seguridad y comodidad que te mereces. No dudes en hacernos saber tus necesidades e inquietudes, estaremos pendientes de ti, en todo momento.",
        prioridad: 1,
        tipo: "text",
        status: 1
    },
    {
        remitente: "Recepción",
        mensaje: "Hola! Tienes una visita.",
        prioridad: 1,
        tipo: "text",
        status: 1
    },
    {
        remitente: "Recepción",
        mensaje: "Te invitamos a pasar a caja a hacer el abono a tu cuenta, si necesitas que te apoyemos con el trámite, no dudes en preguntarnos.",
        prioridad: 2,
        tipo: "text",
        status: 1
    },
    {
        remitente: "Recepción",
        mensaje: "Te recordamos que tu habitación cuenta con todas las amenidades para hacer tu estancia placentera: \n" +
            "• Televisión con cable \n" +
            "• Google HOME \n" +
            "• Bocina con sonido ambiental \n" +
            "• Comunicación con enfermería a través de NURSE CALLING \n" +
            "En caso de que necesiten ayuda no dudes en decirnos, ¡PRIMERO TÚ comodidad! \n",
        prioridad: 2,
        tipo: "text",
        status: 1,
        destino: 1
    },
];

export const defaultMsjsNutricion = [
    {
        remitente: "Nutrición",
        mensaje: "Hola buenos días, te compartimos el menú de hoy, esperamos sea de tu agrado: ",
        prioridad: 1,
        tipo: "text",
        status: 1,
        destino: 1
    },
    {
        remitente: "Nutrición",
        mensaje: "Te recordamos que nuestros alimentos se sirven en los siguientes horarios: \n" +
            "• Desayuno entre 8:00 am y 9:00 am \n" +
            "• Comida entre 1:30 y 2:30 \n" +
            "• Cena entre 6:30 y 7:30 \n",
        prioridad: 2,
        tipo: "text",
        status: 1,
        destino: 1
    },
];

export const defaultMjsDireccion = [
    {
        remitente: "Dirección",
        mensaje: "ALERTA SÍSMICA ",
        prioridad: 4,
        tipo: "ALERTA1",
        status: 1,
        destino: 1
    },
    {
        remitente: "Dirección",
        mensaje: "CÓDIGO VERDE: FAVOR DE EVACUAR LAS INSTALACIONES",
        prioridad: 4,
        tipo: "ALERTA2",
        status: 1,
        destino: 1
    },
    {
        remitente: "Dirección",
        mensaje: "CÓDIGO GRIS: NO salga de su habitación",
        prioridad: 4,
        tipo: "ALERTA3",
        status: 1,
        destino: 1
    }
];

import { Options, Vue } from 'vue-class-component';
import Nutricion from '@/components/Nutricion.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    Nutricion,
  },
})
export default class NutricionView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}

import { Component, Vue } from 'vue-facing-decorator'
import { findAllUsers, deleteUser } from '@/firebase/firestore/fsServices'
import { useToast } from "primevue/usetoast";
import router from '@/router/index'
import Swal from 'sweetalert2'

@Component
export default class Register extends Vue {
    toast = useToast();
    users?: any[];

    mounted(): void {
        findAllUsers().then((data) => {
            this.users = data;
        });
    }

    editUser(uid: string) {
        router.push('/editar_usuario/' + uid,);
    }

    formatPerfil(perfil: number) {
        switch (perfil) {
            case 1:
                return "Habitación";
            case 2:
                return "Enfermería";
            case 3:
                return "Repeción";
            case 4:
                return "Nutrición";
            case 5:
                return "Administración";
        }
    }

    deleteUser(uid: string, user: string) {
        var uidUser = uid;
        Swal.fire({
            title: '¿Desea Eliminar el Registro del usuario: ' + user + '?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then((result) => {
            if(result.isConfirmed){
                deleteUser(uidUser).then((r) => {
                    router.go(0);
                    this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Eliminó el usuario', life: 3600 });
                });
            }
        });
    }
}
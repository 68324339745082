import { Component, Vue } from 'vue-facing-decorator'
import { useToast } from "primevue/usetoast";
import { saveMenssage } from '@/firebase/realtimeDB/services'
import { findAllHabitaciones } from '@/firebase/firestore/fsServices'
import Swal from 'sweetalert2'
import router from '@/router/index'
import { defaultMsjsEnfermeria } from '@/web/ts/Utils'

@Component
export default class Enfermeria extends Vue {
  //Import variables
  toast = useToast();
  defaultMessages = defaultMsjsEnfermeria;

  //Variables
  error: string;
  mensaje: any;
  categorias: any[];
  destino: any[];
  habitaciones: any[];
  selectDestino: number;
  selectDestino2: any[];
  selectDestino3: string;


  //Constructor
  created(): void {
    this.mensaje = {
      remitente: 'Enfermería',
      data: null,
      prioridad: null,
      tipo: 'text',
      timestamp: '',
      status: 1
    }
    this.categorias = [
      { id: 1, valor: "Mensaje" },
      { id: 2, valor: "Recordatorio" },
      { id: 3, valor: "Aviso" },
      { id: 4, valor: "Alerta" },
      { id: 5, valor: "Urgente" },
    ];
    this.destino = [
      { id: 1, valor: "General" },
      { id: 2, valor: "Habitaciones" },
      { id: 3, valor: "Habitación Específica" },
    ];
    findAllHabitaciones().then((data) => {
      this.habitaciones = data;
    });
  }

  //Public Methods
  enviarMensaje(event: any) {
    if (this.validateForm()) {
      switch (this.selectDestino) {
        case 1:
          this.mensaje.timestamp = new Date().getTime();
          for (let hab of this.habitaciones) {
            console.log(hab.idHabitacion);
            saveMenssage(this.mensaje, hab.idHabitacion);
          }
          this.succesSend();
          break;
        case 2:
          if (this.selectDestino2 && this.selectDestino2.length > 0) {
            this.mensaje.timestamp = new Date().getTime();
            for (let idHabitacion of this.selectDestino2) {
              console.log(idHabitacion);
              saveMenssage(this.mensaje, idHabitacion);
            }
            this.succesSend();
          } else {
            this.toast.add({ severity: 'warn', summary: 'Validación', detail: 'Seleccione almenos una habitación', life: 3600 });
          }
          break;
        case 3:
          if (this.selectDestino3) {
            this.mensaje.timestamp = new Date().getTime();
            console.log(this.selectDestino3);
            saveMenssage(this.mensaje, this.selectDestino3);
            this.succesSend();
          } else {
            this.toast.add({ severity: 'warn', summary: 'Validación', detail: 'Seleccione la Habitación', life: 3600 });
          }
          break;
      }
    } else {
      this.toast.add({ severity: 'warn', summary: 'Validación', detail: this.error, life: 3600 });
    }
  }

  formatCategoria(categoria: number) {
    switch (categoria) {
      case 1:
        return "Mensaje";
      case 2:
        return "Recordatorio";
      case 3:
        return "Aviso";
      case 4:
        return "Alerta";
      case 5:
        return "Urgente";
      default:
        return "";
    }
  }

  addMsgToMain(msg: any) {
    this.mensaje.data = msg.mensaje;
    this.mensaje.prioridad = msg.prioridad;
    this.mensaje.tipo = msg.tipo;
    this.mensaje.status = msg.status;
    if (msg.destino) {
      this.selectDestino = msg.destino;
    } else {
      this.selectDestino = null;
    }
  }

  //Private Methods
  private validateForm() {
    if (!this.mensaje.data) {
      this.error = "El mensaje no puede estar vacío";
      return false;
    }
    if (!this.mensaje.prioridad) {
      this.error = "Seleccione la categoria";
      return false;
    }
    if (!this.selectDestino) {
      this.error = "Seleccione un destino";
      return false;
    }
    return true;
  }

  private succesSend() {
    Swal.fire({
      icon: 'success',
      title: 'Mensaje Envíado'
    }).then((result) => {
      if (result.isConfirmed) {
        router.go(0);
      }
    });
  }

}

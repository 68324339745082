import { Vue } from 'vue-class-component';
import { useRoute } from 'vue-router'
import { readUser, updateUser } from '@/firebase/firestore/fsServices'
import { useToast } from "primevue/usetoast";

export default class EditUser extends Vue {
    //Import variables
    route = useRoute();
    toast = useToast();

    //local variables
    uid?: any;
    usuario?: any = {};
    error: string;
    loader = false;
    zonas: any[];

    created(): void {
        this.uid = this.route.params.uid;
        readUser(this.uid).then((data) => {
            this.usuario = data;
        });
        this.zonas = [
            { id: 1, valor: "Habitación" },
            { id: 2, valor: "Enfermería" },
            { id: 3, valor: "Repeción" },
            { id: 4, valor: "Nutrición" },
            { id: 5, valor: "Administración" },
            { id: 6, valor: "Dirección" },
        ];
    }

    updateUser() {
        updateUser(this.usuario).then((data) => {
            this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se acutalizó el usuario correctamente', life: 3600 });
        });
    }

}
import { database } from '../config'
import { ref, push, set, onValue } from "firebase/database";

//References to database tables
const mensajesRef = ref(database, 'mensajes');

//create new chat by user
export function createUserChat(uidchat: string, username: string) {
    const primerMensaje = {
        data: "Bienvenido al chat de la " + username,
        prioridad: 1,
        remitente: "General",
        status: 1,
        timestamp: new Date().getTime(),
        tipo: "text"
    }
    push(ref(database, 'mensajes/' + uidchat), primerMensaje);
}

//CRUD Functions to references
export function saveMenssage(mensaje: any, uidchat: string) {
    push(ref(database, 'mensajes/' + uidchat), mensaje);
}

export async function deleteMessages(uidchat: string) {
    set(ref(database, 'mensajes/' + uidchat), null).then((data) => {
        return true;
    }).catch((err) => {
        return false;
    });
}


//References to database tables
export const notificacionesRef = ref(database, 'notificaciones');

//CRUD Functions to references
export function createNotification(notificacion: any) {
    push(notificacionesRef, notificacion);
}

export function saveNotification(notificacion: any, uid: string) {
    const notRef = ref(database, 'notificaciones/' + uid);
    set(notRef, notificacion);
}

export async function getAllNotificactions() {
    return new Promise((resolve, reject) => {
        const list: any[] = [];
        onValue(notificacionesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                Object.keys(data).forEach((key) => {
                    data[key].uid = key;
                    list.push(data[key]);
                });
            }
            resolve(list); // Resuelve la promesa con la lista de notificaciones
        }, (error) => {
            reject(error); // Rechaza la promesa en caso de error
        });
    });
}

export async function getNumberNotificationsActive(): Promise<number> {
    return new Promise((resolve, reject) => {
        let noNot: number = 0;
        onValue(notificacionesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                Object.keys(data).forEach((key) => {
                    if (data[key].status == 1) {
                        noNot += 1;
                    }
                });
            }
            resolve(noNot); // Resuelve la promesa con la lista de notificaciones
        }, (error) => {
            reject(error); // Rechaza la promesa en caso de error
        });
    });
}

export async function deleteNotificacion(uid: string) {
    set(ref(database, 'notificaciones/' + uid), null).then((data) => {
        return true;
    }).catch((err) => {
        return false;
    });
}
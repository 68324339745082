
import { Options, Vue } from 'vue-class-component';
import ExtrasHabitacion from '@/components/ExtrasHabitacion.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    ExtrasHabitacion,
  },
})
export default class ExtrasHabitacionView extends Vue {
  beforeCreate(): void {
    isCurrentUserExist();
  }
}


import { firestore } from "../config"
import { collection, setDoc, addDoc, limit } from "firebase/firestore";
import { doc, getDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { query, where, orderBy } from "firebase/firestore";

//Tables References
const usersRef = collection(firestore, "usuarios");
const habitacionRef = collection(firestore, "habitaciones");
const extrasHabRef = collection(firestore, "extrasHabitacion");
const doctoresRef = collection(firestore, "doctores");
const diagnosticoRef = collection(firestore, "diagnosticos");

//Users CRUD and Querys Services
export async function createUser(user: any) {
    try {
        const docRef = await setDoc(doc(usersRef, user.uuid), user);
        return { code: 200 };
    } catch (error) {
        return { code: 500, error };
    }
}

export async function readUser(useruid: string) {
    const docRef = doc(firestore, "usuarios", useruid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function updateUser(user: any) {
    const updateRef = doc(firestore, "usuarios", user.uuid);
    await updateDoc(updateRef, user).then((response) => { return true; });
}

export async function deleteUser(uid: string) {
    await deleteDoc(doc(firestore, "usuarios", uid)).then((response) => { return true });
}

export async function findAllUsers() {
    const q = query(usersRef, orderBy("user"));
    const querySnapshot = await getDocs(q);
    const usersList: any = [];
    querySnapshot.forEach((doc) => {
        let user = doc.data();
        if (user.user != "root") {
            usersList.push(doc.data());
        }
    });
    return usersList;
}

export async function findUsersByPerfil(perfil: number) {
    const q = query(usersRef, where("perfil", "==", perfil));
    const querySnapshot = await getDocs(q);
    let listUsers: any = [];
    querySnapshot.forEach((doc) => {
        listUsers.push(doc.data());
    });
    return listUsers;
}

//Habitaciones CRUD and Querys Services
export async function createHabitacion(habitacion: any) {
    try {
        await setDoc(doc(habitacionRef, habitacion.idHabitacion), habitacion);
        return { code: 200 };
    } catch (error) {
        return { code: 500, error };
    }
}

export async function readHabitacion(uid: string) {
    const docRef = doc(firestore, "habitaciones", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function updateHabitacion(habitacion: any) {
    const updateRef = doc(firestore, "habitaciones", habitacion.idHabitacion);
    await updateDoc(updateRef, habitacion).then((response) => { return true; });
}

export async function findAllHabitaciones() {
    const q = query(habitacionRef, orderBy("habitacion", "desc"));
    const querySnapshot = await getDocs(q);
    const habitacionesList: any = [];
    querySnapshot.forEach((doc) => {
        habitacionesList.push(doc.data());
    });
    return habitacionesList;
}

//ExtrasHabitaciones CRUD and Querys Services
export async function createExtrasHabitacion(extrasHabitacion: any) {
    try {
        await setDoc(doc(extrasHabRef, extrasHabitacion.idHabitacion), extrasHabitacion);
        return { code: 200 };
    } catch (error) {
        return { code: 500, error };
    }
}

export async function readExtrasHabitacion(uid: string) {
    const docRef = doc(firestore, "extrasHabitacion", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function saveExtrasHabitacion(extrasHabitacion: any) {
    const updateRef = doc(firestore, "extrasHabitacion", extrasHabitacion.idHabitacion);
    await updateDoc(updateRef, extrasHabitacion).then((response) => { return true; });
}

//Doctores CRUD and Querys Services
export async function createDoctor(doctor: any) {
    try {
        let newDoc = await addDoc(doctoresRef, doctor);
        return newDoc.id;
    } catch (err) {
        return { code: 500, err }
    }
}

export async function readDoctor(uid: string) {
    const docRef = doc(firestore, "doctores", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        return null;
    }
}

export async function updateDoctor(doctor: any) {
    const updateRef = doc(firestore, "doctores", doctor.id);
    await updateDoc(updateRef, doctor).then((response) => { return true; });
}

export async function deleteDoctor(doctor: any) {
    try {
        await deleteDoc(doc(firestore, "doctores", doctor.id));
        return { code: 200 };
    } catch (err) {
        return { code: 500, err }
    }
}

export async function findAllDoctores() {
    const q = query(doctoresRef, orderBy("nombre", "asc"));
    const querySnapshot = await getDocs(q);
    let doctoresList: any = [];
    querySnapshot.forEach((doc) => {
        let doctor = doc.data();
        if (doctor.tipo == "Doctor") {
            doctor.id = doc.id;
            doctoresList.push(doctor);
        }
    });
    return doctoresList;
}
export async function findDoctores(busqueda: string) {
    const q = query(doctoresRef, orderBy("nombre", "asc"),where("tipo", "==","Doctor"),where("nombre", ">=",busqueda),where("nombre", "<=",busqueda+ '\uf8ff'),limit(10));
    const querySnapshot = await getDocs(q);
    let doctoresList: any = [];
    querySnapshot.forEach((doc) => {
        let doctor = doc.data();
        doctoresList.push(doctor);
        
    });
    return doctoresList;
}

export async function findDiagnosticos(busqueda: string) {
    const q = query(diagnosticoRef, orderBy("nombre", "asc"),where("nombre", ">=",busqueda),where("nombre", "<=",busqueda+ '\uf8ff'),limit(10));
    const querySnapshot = await getDocs(q);
    const diagList: any = [];
    querySnapshot.forEach((doc) => {
        diagList.push(doc.data());
        console.log(diagList)
    });
    return diagList;
}

export async function findAllEnfermeros() {
    const q = query(doctoresRef, orderBy("nombre", "desc"));
    const querySnapshot = await getDocs(q);
    let doctoresList: any = [];
    querySnapshot.forEach((doc) => {
        let doctor = doc.data();
        if (doctor.tipo == "Enfermero") {
            doctor.id = doc.id;
            doctoresList.push(doctor);
        }
    });
    return doctoresList;
}
export async function findEnfermeros(busqueda: string) {
    const q = query(doctoresRef, orderBy("nombre", "desc"),where("tipo", "==","Enfermero"),where("nombre", ">=",busqueda),where("nombre", "<=",busqueda+ '\uf8ff'),limit(10));
    const querySnapshot = await getDocs(q);
    let doctoresList: any = [];
    querySnapshot.forEach((doc) => {
        let doctor = doc.data();
        if (doctor.tipo == "Enfermero") {
            doctor.id = doc.id;
            doctoresList.push(doctor);
        }
    });
    return doctoresList;
}
export async function findAllPersonal() {
    const q = query(doctoresRef, orderBy("nombre"));
    const querySnapshot = await getDocs(q);
    let doctoresList: any = [];
    querySnapshot.forEach((doc) => {
        let doctor = doc.data();
        doctor.id = doc.id;
        doctoresList.push(doctor);
    });
    return doctoresList;
}

import { Options, Vue } from 'vue-class-component';
import Register from '@/components/usuarios/Registrer.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
    components: {
        Register,
    },
})
export default class RegisterView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}

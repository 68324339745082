import { Component, Vue } from 'vue-facing-decorator';
import router from '@/router/index';
import { useToast } from "primevue/usetoast";
import { createDoctor } from '@/firebase/firestore/fsServices';

@Component
export default class AltaDoctor extends Vue {
    toast = useToast();
    doctor?: any = {};
    loader: false;
    mensaje: string = "";
    fecha1: Date;

    tipos: any = [
        { id: 1, valor: "Enfermero" },
        { id: 2, valor: "Doctor" },
      ];

    public createDoctor(): void {
        if (this.validateDoctor()) {
            
            if (this.doctor.tipo == "Doctor") {
                this.formatFechas();
                this.doctor.nombre = `DR. ${this.doctor.nombre}`;   
            }
            createDoctor(this.doctor).then(data => {
                router.push('/doctores');
                this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se registro la información de un nuevo doctor', life: 3600 });
            });
        } else {
            this.toast.add({ severity: 'error', summary: 'Formulario', detail: this.mensaje, life: 3600 });
        }
    }

    private validateDoctor() {
        if (this.doctor.nombre == null) {
            this.mensaje = "EL Nombre no puede estar vacío.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.doctor.especialidad == null) {
            this.mensaje = "La especialidad 1 no puede estar vacía.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.doctor.tel == null) {
            this.mensaje = "el teléfono no puede estar vacío.";
            return false;
        }
        if (this.doctor.tipo == "Doctor" && this.fecha1 == null) {
            this.mensaje = "La fecha de nacimiento no puede estar vacía.";
            return false;
        }
        return true;
    }

    private formatFechas() {
        this.doctor.fechaNaci = this.fecha1.getTime();
    }
}
import { Component, Vue } from 'vue-facing-decorator'
import { useToast } from "primevue/usetoast";
import { useRoute } from 'vue-router';
import { readExtrasHabitacion, saveExtrasHabitacion, createExtrasHabitacion } from '@/firebase/firestore/fsServices';
import { ref } from 'vue';
import { uploadHabitacionQRFile } from '@/firebase/storage/storageServices';

@Component
export default class ExtrasHabitacion extends Vue {
  //Import variables
  route = useRoute();
  toast = useToast();
  uidHabitacion?: any;
  habitacion?: any;
  extrasHabitacion?: any = {};
  loader: false;
  error: string;
  isNvo: boolean = false;
  isQRGenereted: boolean = false;

  value = ref('qrcode');

  created(): void {
    this.uidHabitacion = this.route.params.uidHabitacion;
    this.habitacion = this.route.params.habitacion;
    readExtrasHabitacion(this.uidHabitacion).then((data) => {
      if (!data) {
        this.extrasHabitacion = {};
        this.extrasHabitacion.idHabitacion = this.uidHabitacion;
        this.isNvo = true;
      } else {
        this.extrasHabitacion = data;
      }
    });
  }

  generateQRCode() {
    let strWifiCode = "WIFI:S:"
      + this.extrasHabitacion.nombreRed
      + ";T:" + this.extrasHabitacion.cifrado
      + ";P:" + this.extrasHabitacion.passwordRed
      + ";H:" + this.extrasHabitacion.esOculta + ";;";
    this.value = ref(strWifiCode);
    this.extrasHabitacion.isQrUploaded = false;
    this.isQRGenereted = true;
  }

  uploadCanvaToStorage() {
    let canva = document.getElementById('qrCoreGenerated') as HTMLCanvasElement;
    let dataURL = canva.toDataURL();
    const blob = this.dataURItoBlob(dataURL);

    uploadHabitacionQRFile(this.habitacion, blob).then((downloadURL) => {
      console.log(downloadURL);
      this.extrasHabitacion.urlQRImg = downloadURL;
      this.extrasHabitacion.isQrUploaded = true;
      saveExtrasHabitacion(this.extrasHabitacion).then((data) => {
        this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Archivo Subido Correctamente', life: 3600 });
      });
    });
  }

  guardarExtras() {
    if (this.validateExtras()) {
      if (this.isNvo) {
        createExtrasHabitacion(this.extrasHabitacion).then((data) => {
          this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se actualizó la información de la Habitación', life: 3600 });
        });
      } else {
        saveExtrasHabitacion(this.extrasHabitacion).then((data) => {
          this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se actualizó la información de la Habitación', life: 3600 });
        });
      }
    } else {
      this.toast.add({ severity: 'warn', summary: 'Validación', detail: this.error, life: 3600 });
    }
  }

  private validateExtras() {
    if (this.extrasHabitacion.nombreRed == null) {
      this.error = "El nombre de la red no puede estar vacío";
      return false;
    }
    if (this.extrasHabitacion.passwordRed == null) {
      this.error = "La contraseña de la red no puede estar vacía";
      return false;
    }
    return true;
  }

  private dataURItoBlob(dataURI: any) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
  
}

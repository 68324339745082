
import { Options, Vue } from 'vue-class-component';
import Usuarios from '@/components/usuarios/Usuarios.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
    components: {
        Usuarios,
    },
})
export default class UsuariosView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}

import { Component, Vue } from 'vue-facing-decorator'
import { useToast } from "primevue/usetoast";
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword } from "firebase/auth";
import { createUser } from "@/firebase/firestore/fsServices";
import { createUserChat } from "@/firebase/realtimeDB/services"
import { createHabitacion } from "@/firebase/firestore/fsServices";
import { auth } from '@/firebase/config';
import { readUser } from '@/firebase/firestore/fsServices';
import router from '@/router/index';

@Component
export default class Register extends Vue {
  //Import variables
  toast = useToast();
  auth = getAuth();

  //local variables
  error: string;
  loader = false;
  zonas: any[];
  newUser: any;
  confpw: string;
  cuuser: string;
  cupw: string;

  //Constructor
  created(): void {
    this.newUser = {
      uuid: null,
      nombre: null,
      user: null,
      password: null,
      perfil: null,
    }
    this.zonas = [
      { id: 1, valor: "Habitación" },
      { id: 2, valor: "Enfermería" },
      { id: 3, valor: "Repeción" },
      { id: 4, valor: "Nutrición" },
      { id: 5, valor: "Administración" },
      { id: 6, valor: "Dirección" },
    ];
    auth.onAuthStateChanged((user) => {
      if (user) {
        readUser(user.uid).then((data) => {
          this.cuuser = data.user + "@bipperapp.com";
          this.cupw = data.password;
        });
      }
    });
  }

  registerUser() {
    if (this.validateFormR1()) {
      this.loader = true;
      let correo = this.newUser.user + "@bipperapp.com";
      const cuuser = this.cuuser;
      const cupw = this.cupw;
      createUserWithEmailAndPassword(this.auth, correo, this.newUser.password)
        .then((userCredential: any) => {
          // Signed in 
          this.newUser.uuid = userCredential.user.uid;
          updateProfile(userCredential.user, {
            displayName: this.newUser.nombre
          }).then(() => {
            createUser(this.newUser).then((data: any) => {
              if (data.code == 200) {
                if (this.newUser.perfil == 1) {
                  createUserChat(this.newUser.uuid, this.newUser.nombre);
                  let habitacion = {
                    idHabitacion: this.newUser.uuid,
                    habitacion: this.newUser.nombre,
                    status: 0
                  }
                  createHabitacion(habitacion).then((data) => {
                    if (data.code == 200) {
                      this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se creó un nuevo usuario y una nueva habitación', life: 3600 });
                    } else {
                      this.toast.add({ severity: 'error', summary: 'Firebase Error:', detail: data.error, life: 5000 });
                    }
                  });
                } else {
                  this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se creó un nuevo usuario', life: 3600 });
                }
                signInWithEmailAndPassword(this.auth, cuuser, cupw).then((userCredential) => {
                  this.loader = false;
                  router.push({ path: "/registro_usuario" });
                }).catch((error) => {
                  this.toast.add({ severity: 'error', summary: error.code, detail: error.message, life: 5000 });
                });
              } else {
                console.log(data.error);
                this.toast.add({ severity: 'error', summary: 'Firebase Error:', detail: data.error, life: 5000 });
                this.loader = false;
              }
            });
          }).catch((error) => {
            this.toast.add({ severity: 'error', summary: error.code, detail: error.message, life: 5000 });
            this.loader = false;
          });
        }).catch((error) => {
          this.toast.add({ severity: 'error', summary: error.code, detail: error.message, life: 5000 });
          this.loader = false;
        });
    } else {
      this.toast.add({ severity: 'warn', summary: 'Validación', detail: this.error, life: 5000 });
    }
  }

  validateFormR1() {
    if (this.newUser.perfil && this.newUser.user && this.newUser.password && this.newUser.nombre) {
      if (this.newUser.password == this.confpw) {
        return true;
      } else {
        this.error = "Las contraseñas no coinciden"
        return false;
      }
    } else {
      this.error = "Verifique el formulario"
      return false;
    }
  }

}

import { Options, Vue } from 'vue-class-component';
import EditDoctor from '@/components/doctores/EditDoctor.vue';
import { isCurrentUserExist } from '@/firebase/auth/authServices';


@Options({
    components: {
        EditDoctor,
    },
})
export default class PacientesView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}


import { Vue } from 'vue-class-component';
import router from '@/router/index'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { auth } from '@/firebase/config'
import { signOut } from 'firebase/auth';
import { readUser } from '@/firebase/firestore/fsServices'
import { notificacionesRef, getNumberNotificationsActive } from '@/firebase/realtimeDB/services';
import { onChildAdded, onChildChanged } from 'firebase/database';
import { useToast } from "primevue/usetoast";
import Cookies from 'js-cookie';

export default class App extends Vue {
  route = useRoute();
  toast = useToast();
  user: any = null;
  path: any;
  nombre: string;
  perfil: number = 0;
  notificaciones: number = 0;
  cookies: any;

  beforeCreate(): void {
    this.path = computed(() => this.route.name);
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        readUser(user.uid).then((data) => {
          this.perfil = data.perfil;
          Cookies.set('CurrentUserProfileLvl', data.perfil);
        });
      } else {
        this.user = null;
      }
    });
  }

  created(): void {
    if (this.user) {
      this.setupChildAddedListener();
      this.updateNotificationsCount(false);
    }
  }

  setupChildAddedListener(): void {
    onChildAdded(notificacionesRef, (childSnapshot) => {
      const addedData = childSnapshot.val();
      let b = false;
      if (addedData.status == 1) {
        b = true;
      }
      this.updateNotificationsCount(b);
    });
    onChildChanged(notificacionesRef, (childSnapshot) => {
      this.updateNotificationsCount(false);
    });
  }

  updateNotificationsCount(b: boolean): void {
    getNumberNotificationsActive().then((data) => {
      if (this.perfil >= 5 && data >= this.notificaciones && b) {
        this.toast.add({ severity: 'info', detail: "Nueva Notificación", life: 3600 });
      }
      this.notificaciones = data;
    });
  }

  toggleNavBar() {
    return this.path.value !== "login" && this.path.value !== "notFound";
  }

  cerrarSesion() {
    signOut(auth).then(() => {
      router.push({ path: "/login" });
    }).catch((error) => {
      // An error happened.
    });
  }


}

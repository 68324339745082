
import { Options, Vue } from 'vue-class-component';
import Doctores from '@/components/doctores/Doctores.vue';// @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices';


@Options({
    components: {
        Doctores,
    },
})
export default class PacientesView extends Vue {
    beforeCreate(): void {
      isCurrentUserExist();
  }
}


import { Options, Vue } from 'vue-class-component';
import Home from '@/components/Home.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'
@Options({
  components: {
    Home,
  },
})
export default class HomeView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}

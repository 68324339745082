
import { Options, Vue } from 'vue-class-component';
import Enfermeria from '@/components/Enfermeria.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    Enfermeria,
  },
})
export default class HomeView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}

import { getCurrentUsername } from '@/firebase/auth/authServices';
import { readHabitacion, updateHabitacion } from '@/firebase/firestore/fsServices';
import { createNotification } from '@/firebase/realtimeDB/services';
import { useToast } from "primevue/usetoast";
import { Component, Vue } from 'vue-facing-decorator';
import { useRoute } from 'vue-router';
import router from '@/router/index'
import { findDoctores, findEnfermeros, findDiagnosticos } from '@/firebase/firestore/fsServices';

@Component
export default class EditHabitacion extends Vue {
    route = useRoute();
    toast = useToast();

    //local variables
    uid?: any;
    habitacion?: any = {};
    sexos: any[];
    loader: false;
    error: string;
    fecha1: Date;
    fecha2: Date;
    user: string;

    doctores: any[];
    diagnosticos: any[];
    enfermeros: any[];
    doctoresFilter: any[];
    diagnosticosFilter: any[];
    enfermerosFilter: any[];

    beforeCreate(): void {
        getCurrentUsername().then((username) => {
            this.user = username + "";
        });
    }

    created(): void {
        this.uid = this.route.params.uid;
        readHabitacion(this.uid).then((data) => {
            this.habitacion = data
            if (data.fechaing) {
                this.fecha1 = new Date(data.fechaing);
            }
            if (data.fechaegr) {
                this.fecha2 = new Date(data.fechaegr);
            }
        });
    }

    mounted(): void {
        /* findAllDoctores().then((data) => {
            this.doctores = data;
        }); */
       /*  findAllEnfermeros().then((data) => {
            this.enfermeros = data;
        }); */
        this.sexos = [
            { id: 1, valor: "Masculino" },
            { id: 2, valor: "Femenino" },
        ];
    }

    guardarPaciente() {
        if (this.validatePaciente()) {
            this.formatFechas();
            if (this.habitacion.habitacion.includes("Urgencias") && this.habitacion.status == 0) {
                let notificacion = {
                    content: "Nuevo Paciente Registrado en Urgencias. Se necesita abrir la cuenta del paciente.",
                    timestamp: new Date().getTime(),
                    triggerBy: this.user,
                    status: 1,
                    data: this.habitacion.idHabitacion
                }
                createNotification(notificacion);
            }
            this.habitacion.status = 1;
            if (typeof this.habitacion.medicoTratante === 'string') {
                this.habitacion.medicoTratante = {
                    nombre:this.habitacion.medicoTratante
                }
              } 
              if (typeof this.habitacion.nombreEnfermero === 'string') {
                this.habitacion.nombreEnfermero = {nombre:this.habitacion.nombreEnfermero}
              } 
              if (typeof this.habitacion.diagnosticoi === 'string') {
                this.habitacion.diagnosticoi = {nombre:this.habitacion.diagnosticoi};
              } 
            
            updateHabitacion(this.habitacion).then((data) => {
                router.push({ path: "/habitaciones" });
                this.toast.add({ severity: 'success', summary: 'Proceso Exitoso', detail: 'Se actualizó la información del paciente', life: 3600 });
            });
        } else {
            this.toast.add({ severity: 'warn', summary: 'Validación', detail: this.error, life: 3600 });
        }
    }

    search(event: any) {
        
        setTimeout(async () => {
            if (!event.query.trim().length) {
                this.doctoresFilter = [];
            } else {
                let busqueda = event.query.toUpperCase().trim();
                this.doctoresFilter = await findDoctores(busqueda);
            }
        });
    }

    search2(event: any) {
        setTimeout(async () => {
            if (!event.query.trim().length) {
                this.enfermerosFilter = [];
            } else {
                let busqueda = event.query.toUpperCase().trim();
                this.enfermerosFilter = await findEnfermeros(busqueda);
                
            }
        });
    }
    search3(event: any) {
        setTimeout(async () => {
            if (!event.query.trim().length) {
                this.diagnosticosFilter = [];
            } else {
                let busqueda = event.query.toUpperCase().trim();
                this.diagnosticosFilter = await findDiagnosticos(busqueda);
                
                /* this.diagnosticosFilter = this.diagnosticos.filter((diagnostico) => {
                    return diagnostico.nombre.toLowerCase().startsWith(event.query.toLowerCase());
                }); */
            }
        });
    }

    onChangeDoctor(event: any) {
        this.habitacion.medicoTratante = this.habitacion.medicoTratante.nombre;
    }
    onChangeDiag(event: any){
        this.habitacion.diagnosticoi = this.habitacion.diagnosticoi.nombre
    }
    onChangeEnfermero(event: any) {
        console.log(event)
        //this.habitacion.nombreEnfermero = this.habitacion.nombreEnfermero.nombre;
    }

    private formatFechas() {
        this.habitacion.fechaing = this.fecha1.getTime();
        if (this.habitacion.fecha2) {
            this.habitacion.fechaegr = this.fecha2.getTime();
        }
    }

    private validatePaciente() {
        if (this.habitacion.nombrePaciente == null) {
            this.error = "El nombre del paciente no puede estar vacío";
            return false;
        }
        if (this.habitacion.edad == null) {
            this.error = "La edad no puede estar vacía";
            return false;
        }
        if (this.fecha1 == null) {
            this.error = "La fecha de ingreso no puede estar vacía";
            return false;
        }
        if (this.habitacion.diagnosticoi == null) {
            this.error = "El Diagnóstico iniciar no puede esta vacío";
            return false;
        }
        if (this.habitacion.medicoTratante == null) {
            this.error = "El nombre del médico no puede estar vacío";
            return false;
        }
        if (this.habitacion.isalergia && this.habitacion.alergias == null) {
            return false;
        }
        return true;
    }

}

import { Options, Vue } from 'vue-class-component';
import Direccion from '@/components/Direccion.vue'; // @ is an alias to /src
import { isCurrentUserExist } from '@/firebase/auth/authServices'

@Options({
  components: {
    Direccion,
  },
})
export default class DireccionView extends Vue {
  beforeCreate(): void {
      isCurrentUserExist();
  }
}


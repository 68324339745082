import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

/*
const firebaseConfig = { // Preproduction Configuration
  apiKey: "AIzaSyD3iA8Zv4M483DtJXR6Fsm8_4u9LZf7a4M",
  authDomain: "adminhospital-d8501.firebaseapp.com",
  databaseURL: "https://adminhospital-d8501-default-rtdb.firebaseio.com",
  projectId: "adminhospital-d8501",
  storageBucket: "adminhospital-d8501.appspot.com",
  messagingSenderId: "290650733749",
  appId: "1:290650733749:web:d6243cb8e352410be5d2fc",
  measurementId: "G-H2G1CJLN4M"
};
*/
const firebaseConfig = { // Production Configuration
  apiKey: "AIzaSyDia5nx-HRTpAVKEmK6CVZUKZ1ydT1xJQ0",
  authDomain: "hospital-b9fdf.firebaseapp.com",
  databaseURL: "https://hospital-b9fdf-default-rtdb.firebaseio.com",
  projectId: "hospital-b9fdf",
  storageBucket: "hospital-b9fdf.appspot.com",
  messagingSenderId: "1021369906611",
  appId: "1:1021369906611:web:fd81d714962cd127ca07c6",
  measurementId: "G-X5RL6G2N3M"
}; 

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const firestore = getFirestore(app);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

// Initialize Storage and get a reference to the service
export const storage = getStorage(app);